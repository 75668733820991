import {Injectable} from '@angular/core';
import {AssetDetail} from '../../models/asset-detail';
import {GlobalDataService} from './globaldata.service';
import {SiteDetail} from '../../models/site-detail';
import {environment} from '../../../environments/environment';

/**
 * Created by lsubramaniam on 10/27/17.
 */

@Injectable()
export class UtilityService {
  constructor(private gd: GlobalDataService) {
  }

  public getSelectedOfferChainId(siteDetail: SiteDetail) {
    const chainIdList = [],
      weightList = [];
    let selOfferChainId = '';
    let counter;
    if (siteDetail) {
      const randomCheck = {};
      if (
        siteDetail.offerChainDetails != null &&
        siteDetail.offerChainDetails &&
        siteDetail.offerChainDetails.length > 0
      ) {
        if (siteDetail.offerChainDetails.length > 1) {
          for (
            counter = 0;
            counter < siteDetail.offerChainDetails.length;
            counter++
          ) {
            const offerChain = siteDetail.offerChainDetails[counter];
            chainIdList.push(offerChain.offerChainId);
            weightList.push(offerChain.percentage);
            randomCheck[offerChain.offerChainId] = 0;
          }
          selOfferChainId = this.getRandomOfferChainItem(
            chainIdList,
            weightList
          );
        } else {
          if (
            siteDetail.offerChainDetails[0] != null &&
            siteDetail.offerChainDetails[0].assetDetails != null &&
            siteDetail.offerChainDetails[0].assetDetails
          ) {
            if (siteDetail.offerChainDetails[0].offerChainId) {
              selOfferChainId = siteDetail.offerChainDetails[0].offerChainId;
            }
          }
        }
      }
      if (selOfferChainId != null) {
        siteDetail.offerChainId = selOfferChainId;
        this.gd.siteParams.selOfferChainId = selOfferChainId;
      }
    }
    return selOfferChainId;
  }

  public getAssetDetail(siteDetail: SiteDetail, selOfferChainId: string) {
    let assetDetails = [];
    if (
      siteDetail.offerChainDetails != null &&
      siteDetail.offerChainDetails &&
      siteDetail.offerChainDetails.length > 0
    ) {
      if (siteDetail.offerChainDetails.length > 1) {
        let counter;
        const assetDetailsList = [];
        for (
          counter = 0;
          counter < siteDetail.offerChainDetails.length;
          counter++
        ) {
          const offerChain = siteDetail.offerChainDetails[counter];
          assetDetailsList[offerChain.offerChainId] = offerChain.assetDetails;
        }
        if (selOfferChainId) {
          siteDetail.offerChainId = selOfferChainId;
          const selAssetDetails = assetDetailsList[selOfferChainId];
          if (selAssetDetails) {
            assetDetails = selAssetDetails;
          }
        }
      } else {
        if (
          siteDetail.offerChainDetails[0] != null &&
          siteDetail.offerChainDetails[0].assetDetails != null &&
          siteDetail.offerChainDetails[0].assetDetails
        ) {
          assetDetails = siteDetail.offerChainDetails[0].assetDetails;
          if (siteDetail.offerChainDetails[0].offerChainId) {
            siteDetail.offerChainId =
              siteDetail.offerChainDetails[0].offerChainId;
          }
        }
      }
    }

    if (assetDetails) {
      this.gd.assetDetails = assetDetails;

      let asset = null;
      if (this.gd.queryParams && this.gd.queryParams.assetId) {
        asset = this.getValidContentByParentAssestId(
          this.gd.queryParams.assetId
        );
        if (asset == null) {
          asset = this.getContentByAction('start');
        }
      } else {
        asset = this.getContentByAction('start');
      }
      if (asset != null) {
        this.gd.asset = asset;
        // ToDo Tracking we need revisit
        // track(globalStoreData.landingPageVisitActivityType);
      }
    }
    // Load Placement Model for Tracking
    // ToDo revisit placement Model for tracking
    /*
     if (globalStoreData.site.placementModel != null
     && !_.isEmpty(globalStoreData.site.placementModel)) {
     globalStoreData.placement = globalStoreData.site.placementModel;
     }*/
  }

  public setUpQueryStringDetails() {
    this.gd.vars = this.getUrlVars();
    if (this.gd.vars) {
      if (this.gd.vars['cid']) {
        this.gd.queryParams.campaignId = this.gd.vars['cid'];
      }
      if (this.gd.vars['eid']) {
        this.gd.queryParams.effortId = this.gd.vars['eid'].substring(0, 9);
      }
      if (this.gd.vars['plcid']) {
        this.gd.queryParams.placementId = this.gd.vars['plcid'];
      }
      if (this.gd.vars['splcid']) {
        this.gd.queryParams.sellerPlacementId = this.gd.vars['splcid'];
      }
      if (this.gd.vars['assetId']) {
        this.gd.queryParams.assetId = this.gd.vars['assetId'];
      }
      if (this.gd.vars['chainId']) {
        this.gd.queryParams.offerChainId = this.gd.vars['chainId'];
      }
      if (this.gd.vars['dbloptinparams']) {
        this.gd.queryParams.doubleOptInParams = this.gd.vars['dbloptinparams'];
      }
      if (this.gd.vars['src']) {
        this.gd.queryParams.src = this.gd.vars['src'];
      }
      if (this.gd.vars['coi']) {
        this.gd.queryParams.coi = this.gd.vars['coi'];
      }
      if (this.gd.vars['coiparams']) {
        this.gd.queryParams.coiParams = this.gd.vars['coiparams'];
      }
      if (this.gd.vars['email'] || this.gd.vars['emailaddress']) {
        const urlEncodedEmail = this.gd.vars['email'] || this.gd.vars['emailaddress'];
        this.gd.user.email = this.getValidEmailFromUrlParam(urlEncodedEmail);
      }
      if (this.gd.vars['utm_source']) {
        this.gd.queryParams.qryUtm_source = this.gd.vars['utm_source'];
      }
      if (this.gd.vars['utm_medium']) {
        this.gd.queryParams.qryUtm_medium = this.gd.vars['utm_medium'];
      }
      if (this.gd.vars['utm_campaign']) {
        this.gd.queryParams.qryUtm_campaign = this.gd.vars['utm_campaign'];
      }
      if (this.gd.vars['utm_term']) {
        this.gd.queryParams.qryUtm_term = this.gd.vars['utm_term'];
      }
      if (this.gd.vars['utm_content']) {
        this.gd.queryParams.qryUtm_content = this.gd.vars['utm_content'];
      }
      if (this.gd.vars['channel']) {
        this.gd.queryParams.channel = this.gd.vars['channel'];
      }
      if (this.gd.vars['oneClick']) {
        this.gd.queryParams.oneClick = this.gd.vars['oneClick'];
      }
      if (this.gd.vars['emailjobid']) {
        this.gd.queryParams.qryEmailJobId = this.gd.vars['emailjobid'];
      }
      if (this.gd.vars['encryptedSnaid']) {
        this.gd.queryParams.encryptedSnaid = this.getQueryStringValue('encryptedSnaid');
      }
      if (this.gd.vars['snaid']) {
        this.gd.queryParams.snaid = this.getQueryStringValue('snaid');
      }
      if (this.gd.vars['clickid']) {
        this.gd.queryParams.clickId = this.getQueryStringValue('clickid');
      }
    }
  }

  private getValidEmailFromUrlParam(urlEncodedEmail: string): string {
    let email = decodeURIComponent(urlEncodedEmail);
    if (!this.isValidEmail(email)) {  // assuming it's a base64-encoded string
      email = this.decodeEmail(email);
      if (email && !this.isValidEmail(email)) {
        console.log('Decoded email is not valid');
        return '';
      }
    }
    return email.trim();
  }

  private decodeEmail(encodedEmail: string): string {
    try {
      return atob(encodedEmail);
    } catch (e) {
      console.log('Failed to decode email');
      return '';
    }
  }

  private getQueryStringValue(key) {
    return decodeURIComponent(window.location.search.replace(new RegExp('^(?:.*[&\\?]'
      + encodeURIComponent(key).replace(/[\.\+\*]/g, '\\$&') + '(?:\\=([^&]*))?)?.*$', 'i'), '$1'));
  }


  public getUrlVars() {
    const vars = [];
    let hash;
    const hashes = window.location.href
      .slice(window.location.href.indexOf('?') + 1)
      .split('&');
    for (let i = 0; i < hashes.length; i++) {
      hash = hashes[i].split('=');
      vars.push(hash[0]);
      vars[hash[0]] = hash[1];
    }
    return vars;
  }

  private getRandomOfferChainItem(
    chainIdList: Array<string>,
    weightList: Array<number>
  ) {
    const totalWeight = weightList.reduce(function (prev, cur, i, arr) {
      return prev + cur;
    });

    const randomNum = this.rand(0, totalWeight);
    let weightSum = 0;

    for (let i = 0; i < chainIdList.length; i++) {
      weightSum += weightList[i];
      weightSum = +weightSum.toFixed(2);

      if (randomNum <= weightSum) {
        return chainIdList[i];
      }
    }
  }

  private rand(min: number, max: number) {
    return Math.random() * (max - min) + min;
  }

  private getContentByAction(action: string) {
    if (action) {
      action = action.toLowerCase();
      // ToDo: Avoid For loop
      for (let i = 0; i < this.gd.assetDetails.length; i++) {
        const assetDetail = this.gd.assetDetails[i];
        if (
          assetDetail != null &&
          assetDetail.action != null &&
          assetDetail.action.toLowerCase() === action
        ) {
          if (assetDetail.destAssetId) {
            return assetDetail;
          }
          return null;
        }
      }
    }
    return null;
  }

  private getValidContentByParentAssestId(assetId: string) {
    if (assetId) {
      for (let i = 0; i < this.gd.assetDetails.length; i++) {
        const assetDetail = this.gd.assetDetails[i];
        if (
          assetDetail != null &&
          assetDetail.destParentAssetId != null &&
          assetDetail.destParentAssetId.toLowerCase() === assetId.toLowerCase()
        ) {
          // if (assetDetail.destAssetId && assetDetail.destAssetType.toLowerCase() != 'upsell'
          //  && assetDetail.destAssetType.toLowerCase() != 'downsell'
          //  && assetDetail.destAssetType.toLowerCase() != 'crosssell'
          //  && assetDetail.destAssetType.toLowerCase() != 'thankyou') {
          return assetDetail;
          // }
          // return null;
        }
      }
    }
    return null;
  }

  private getAssetUrl(asset: AssetDetail) {
    return asset.destAssetLocation;
  }

  public loadNextAsset(action: string) {
    const asset = this.getContentByAssetAndAction(
      this.gd.asset.sourceAssetId,
      action
    );
    this.gd.asset = asset;
    if (asset != null) {
      // ToDo Tracking
      // track(globalStoreData.clickActivityType);
    }
  }

  private getContentByAssetAndAction(assetId: string, action: string) {
    if (action && assetId) {
      action = action.toLowerCase();
      for (let j = 0; j < this.gd.assetDetails.length; j++) {
        const assetDetail = this.gd.assetDetails[j];
        if (
          assetDetail != null &&
          assetDetail.action != null &&
          assetDetail.action.toLowerCase() === action &&
          assetDetail.sourceAssetId != null &&
          assetDetail.sourceAssetId === assetId
        ) {
          return assetDetail;
        }
      }
    }
    return null;
  }

  public getContentByOfferIdAndType(offerId: string, assetType: string) {
    if (offerId && assetType) {
      offerId = offerId.toLowerCase();
      assetType = assetType.toLowerCase();
      for (let j = 0; j < this.gd.assetDetails.length; j++) {
        const assetDetail = this.gd.assetDetails[j];
        if (
          assetDetail != null &&
          assetDetail.sourceOfferId != null &&
          assetDetail.sourceOfferId.toLowerCase() === offerId &&
          assetDetail.sourceAssetType != null &&
          assetDetail.sourceAssetType.toLowerCase() === assetType
        ) {
          return assetDetail;
        }
      }
    }
    return null;
  }

  public isValidEmail(email: string) {
    if (email) {
      const emailReg = /^[a-zA-Z0-9_!#$%&’*+\/=?`{|}~^.-]+@[a-zA-Z0-9.-]+$/;
      return emailReg.test(email);
    }
    return false;
  }

  public isValidZipcode(zipcode: string) {
    // TODO: validate the zip code
    /*if (zipcode) {
      const emailReg = /^\d{5}(?:[-\s]\d{4})?$/;
      return emailReg.test(zipcode);
    }*/
    return true;
  }

  isValidPhoneNumber(phoneNumber: string) {
    if (phoneNumber) {
      const phoneNumberReg = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
      return phoneNumberReg.test(phoneNumber);
    }
    return false;
  }

  public updateQueryStringParamForUrl(uri, key, value) {
    let re = new RegExp('([?&])' + key + '=.*?(&|$)', 'i');
    let separator = uri.indexOf('?') !== -1 ? '&' : '?';
    if (uri.match(re)) {
      return uri.replace(re, '$1' + key + '=' + value + '$2');
    } else {
      return uri + separator + key + '=' + value;
    }
  }

  public updateQueryStringParam(key: string, value: string) {
    const baseUrl = [
        location.protocol,
        '//',
        location.host,
        location.pathname
      ].join(''),
      urlQueryString = document.location.search,
      newParam = key + '=' + value;
    let params = '?' + newParam;

    if (urlQueryString) {
      const keyRegex = new RegExp('([?&])' + key + '[^&]*');

      // If param exists already, update it
      if (urlQueryString.match(keyRegex) !== null) {
        params = urlQueryString.replace(keyRegex, '$1' + newParam);
      } else {
        // Otherwise, add it to end of query string
        params = urlQueryString + '&' + newParam;
      }
    }
    window.history.replaceState({}, '', baseUrl + params);
  }

  getQueryByName(name: string, url?: string) {
    if (!url) {
      url = (<any>window).location.href;
    }
    name = name.replace(/[\[\]]/g, '\\$&');
    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) {
      return null;
    }
    if (!results[2]) {
      return '';
    }
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  getBrowserInfo() {
    const userAgent = navigator.userAgent;
    let elementMatches;
    let matches =
      userAgent.match(
        /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
      ) || [];
    if (/trident/i.test(matches[1])) {
      elementMatches = /\brv[ :]+(\d+)/g.exec(userAgent) || [];
      return 'IE ' + (elementMatches[1] || '');
    }
    if (matches[1] === 'Chrome') {
      elementMatches = userAgent.match(/\b(OPR|Edge)\/(\d+)/);
      if (elementMatches != null) {
        return elementMatches
          .slice(1)
          .join(' ')
          .replace('OPR', 'Opera');
      }
    }
    matches = matches[2]
      ? [matches[1], matches[2]]
      : [navigator.appName, navigator.appVersion, '-?'];
    if ((elementMatches = userAgent.match(/version\/(\d+)/i)) != null) {
      matches.splice(1, 1, elementMatches[1]);
    }
    return matches.join(' ');
  }

  public scrollToBottom(element) {
    element.scrollTop = element.scrollHeight - element.clientHeight;
  }

  public getSplittedAssetUrl(location: String): string[] {
    const splitted = location.split(environment.env);
    const afterS3Url = splitted[1];
    const splittedAfterS3Url = afterS3Url.split('/');
    return splittedAfterS3Url;
  }

  public contains(text, value) {
    return (text ? text.indexOf(value) > -1 : false);
  }

  public getVersionedUrl(url: String): string {
    let assetDetailsUrl = url;
    if (!url.includes(environment.ASSET_DETAILS_API)) {
      const withoutProtocol = url.replace('https:\/\/', '').replace('http:\/\/', '');
      assetDetailsUrl = environment.ASSET_DETAILS_API + withoutProtocol
        .substring(withoutProtocol.indexOf('\/'));
    }

    if (assetDetailsUrl.includes('versionId')) {
      return assetDetailsUrl.replace('\/versionId', '\?versionId') + '&ts=' + Date.now();
    }

    return assetDetailsUrl + '?ts=' + Date.now();
  }
}
