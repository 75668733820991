export const environment = {
  APP_ENV: 'PROD',
  production: true,
  env: 'prod',
  SITE_DETAILS_API: '/site/api/v1/getSiteDetails',
  ASSET_DETAILS_API : 'https://marketingassets.marketwise.com',
  OFFER_EXPIRY_PAGE: 'https://marketingassets.marketwise.com/test/templates/OfferEndedPage/offer_ended_page.html',
  CREATE_ELETTER_API: '/api/v1/optin/eletter',
  CREATE_HOTLIST_API: '/api/v1/optin/hotlist',
  CREATE_COI_API: '/api/v1/optin/customer-settings/coi',
  DOUBLE_OPTIN_SEND_EMAIL_API: '/api/v1/optin/customer-settings/doi',
  EVENTSTREAM_API: '/snowflake/generic',
  EVENTSTREAM_API_KEY: 'g5wZnpMOAV6d3DVWjACKU9Pz86tj10Es39Avoa3n',
  DATADOG_CLIENT_TOKEN: 'pub2a1eacb4678c8ac666513170783471aa',
  BSS_PIXEL_SRC: '/store/PROD/js/bss-px.min.js',
  SNOW_PLOW_COLLECTOR_URL: 'com-marketwise-prod1.collector.snplow.net'
};
